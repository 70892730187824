import React from "react"
import styles from '../styles/404.module.scss'
import boredCat from "../images/404-Image.png"

const ContactSuccess = () => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={boredCat} alt="bored-cat"/>
      </div>
      <div className={styles.text}>
        <h1>Thank you for your Message</h1>
        <p>
         We usual respond after 48 hours of you sending this message
        </p>
      </div>
    </div>
  )
}

export default ContactSuccess
